import MixinConfirm from "@/components/mixins/MixinConfirm";
import printManager from "@/plugins/print-manager-prueba";
export default {
  mixins: [MixinConfirm],
  data() {
    return {
      urlSave: "api/v1/expense",
      cashRegister: null,
    }
  },
  mounted() {
    this.cashRegister = this.$store.getters.installation;
  },
  watch: {
    '$store.getters.installation'(val) {
      if (val) {
        this.cashRegister = val;
      }
    },
    cashRegister(val) {
      this.search();
    }
  },
  methods: {
    search(params, page) {
      const { data, paginate, order } = this.getParamsForQuery(params, page);
      if (this.cashRegister) {
        this.querySelections({ ...data, cash_register: this.cashRegister.id }, paginate, order).then(data => {
          this.items = data.items
          this.total = data.total;
          this.moduleActions = data.module_actions;
          this.itemsKey++;
        });
      }
    },
    changeState(url, values, status, text = null, typeModal) {
      return new Promise((resolve, reject) => {
        typeModal(this.$t(text)).then((response) => {
          if (response) {
            if (!values) {
              values = {};
            }
            values["status"] = status;
            values["note"] = response;
            this.$http
              .post(
                url,//this.urlSave + "/" + this.item.id,
                values,
                true,
                true
              )
              .then((response) => {
                if (response.success) {
                  /**/
                  resolve(response)
                } else {
                  reject(response)
                }
              });
          } else {
            resolve(false)
          }
        });
      })
    },
    allowUpdate() {
      if (this.item) {
        if (
          !this.item.document_state ||
          (this.item.document_state && this.item.document_state.id == "draft")
        ) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    executePrintPdf(item) {
      return new Promise((resolve, reject) => {
        this.$http.get('api/v1/expense-pdf/' + item.id, null, true)
          .then(data => {
            let { item } = data;
            if (data.success) {
              window.open(this.$filters.pathPublicImage('tmp/' + data.item));
            }
            resolve({ item });
          }).catch(err => {
            console.log(err);
          });
      });
    },
    executePrintReceiptPos(item) {
      return new Promise((resolve, reject) => {
        this.$http.get('api/v1/expense-receipt-pos/' + item.id, null, true)
          .then(data => {
            let { item } = data.data;
            if (data.success) {
              printManager.printFromFormat(data.data, data.type);
            }
            resolve({ item });
          }).catch(err => {
            console.log(err);
          });
      });
    },
    executeAnnulled(item) {
      const values = {};
      this.changeState(
        this.urlSave + "/" + item.id,
        values,
        "annulled",
        "enter_cancellation_reason",
        this.openDialogText
      ).then((response) => {
        if (response) {
          this.$nextTick(() => {
            this.$emit("change:item", response);
          });
        }
      });
    },
    executeGet(item) {
      this.$refs.modalItem.renderItem(item);
    }
  }
}
