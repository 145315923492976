<template>
    <v-dialog v-model="dialog" width="500" style="background-color:white">
    <v-card class="pa-3">
            <v-row no-gutters>
                                                            
                <v-col cols="12" class=" list-mobile-field">
                    {{$t('expense_date')}}:
                    {{ $filters.date(item.expense_date) }}
                </v-col>
                                                                
                <v-col cols="12" class=" list-mobile-field">
                    {{$t('consecutive_expense')}}:
                    {{ item.consecutive_expense }}
                </v-col>
                                                                
                <v-col cols="12" class=" list-mobile-field">
                    {{$t('person')}}:
                    {{ $filters.name(item.person) }}
                </v-col>
                                                                
                <v-col cols="12" class=" list-mobile-field">
                    {{$t('document_state')}}:
                    {{ $filters.name(item.document_state) }}
                </v-col>
                                                                
                <v-col cols="12" class=" list-mobile-field">
                    {{$t('total_value')}}:
                    {{ $filters.currency(item.total_value) }}
                </v-col>
                                            </v-row>
            <v-card-actions class="d-flex justify-end">
                <v-btn color="secondary" @click="dialog = false;" small class="btn--close" >
                <v-icon small>mdi-close</v-icon>{{$t('Cerrar')}} </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        data() {
            return {
                dialog: false,
                item: {}
            };
        },
        methods: {
            renderItem(item) {
                this.item = item;
                this.dialog = true;
            }
        }
    }
</script>
